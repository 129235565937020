import React from 'react';
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
const WufooForm = require('react-wufoo-embed');

const RequestOne = () => {
    return (
        <>
                    <div>
              <WufooForm userName="mcnairmedia" formHash="rroqi4b0zjbfko" header="hide" />
            </div>
            <div className="row row--15">
                <div className="col-lg-12">
                <center><h3>Or Contact Us By One of the Methods Below</h3>                        <br></br>
</center>

                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Contact Phone Number</h4>
                                        <p><a href="tel:770.365.6889">770.365.6889</a></p>
                                        <br></br>
                                        <br></br>

                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Our Email Address</h4>
                                        <p><a href="mailto:contact@jstevenspainting.com">contact@jstevenspainting.com</a></p>
                                        <br></br>
                                        <br></br>

                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Our Location</h4>
                                        <p>2288 Marietta Highway</p>
                                        <p>Suite 190</p>
                                        <p>Canton GA, 30114</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default RequestOne;