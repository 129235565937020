import React from 'react';
import {Link} from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";
import { Helmet } from 'react-helmet';
import HeaderOne from '../common/header/HeaderOne';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import AboutFive from '../elements/about/AboutFive';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import TestimonialOne from "../elements/testimonial/TestimonialOne";
import Separator from "../elements/separator/Separator";
import ProgressbarTwo from "../elements/progressbar/ProgressbarTwo";
import BrandFour from "../elements/brand/BrandFour";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";
import ContactOne from "../elements/contact/ContactOne";
import OnlineReviewsAwards from "../elements/contact/OnlineReviewsAwards";
import CalltoActionBottom from "../elements/calltoaction/CalltoActionBottom";
import RequestOne from "../elements/contact/RequestOne";



var BlogListData = BlogClassicData.slice(0, 3);


const InteriorPainting = () => {
    return (
        <>
            <Helmet>
        <title>Atlanta Interior Painting Company | J. Stevens Painting</title>
        <meta name="description" content="Our interior painting services at J. Stevens Painting are so clean, you almost wouldn't even know were there... except the fabulous interior painting job!" />
        <meta name="keywords" content="painting, painter, residential painting" />
      </Helmet>            <main className="page-wrapper">
                
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-perosonal-portfolio height-750 bg_image" data-black-overlay="1" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/banner/interior-painting.jpg)`}}>
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="col-12">
                                <div className="inner text-center">
                                    <h1 className="title">Interior Painting Company</h1>
                                    <div className="button-group mt--30">
                                        <Link className="btn-default round btn-border btn-large" to="tel:770.365.6889">Call 770.365.6889</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <AboutFive />

                <div className="container">

                <center><h2>Metro Atlanta & North Georgia <br/>Interior Painting Services</h2></center>
                <br></br>
                <p>Our interior painting services at J. Stevens Painting are so clean, you almost wouldn't even know were there... except the fabulous interior painting job! At J. Stevens Painting, we believe that your home should not have to bear the brunt of clean-up for professional painters. This is why we bring our own drop cloths and furniture coverings, and our team is instructed to leave each interior space cleaner when they leave than when they arrive. If you have had nightmare images of scraping paint drops off of your floor, you can put those to rest. It simply will not happen when you trust your interior painting to J. Stevens Painting.</p>
<br></br>

<center><h3>How We Prep for an Interior Painting Job</h3></center>
                <br></br>
                <p>The right prep for the smoothest applications is one of the keys to successful interior painting. Our process ensures that the walls and ceilings are ready for a successful interior painting job. Our interior painting contractors are trained and ready to do what needs to be done to make sure that your walls and ceilings are in the best possible condition for interior painting:</p>
                <li>Drywall repairs, from small holes to entire sheets</li>
                <li>Application of desired wall textures</li>
                <li>Removal of popcorn ceilings and other dated wall textures</li>
                <li><b>And so much more!</b></li>
                <br></br>
                <p>Since our creation in 1998, J. Stevens Painting has been proud to offer the best interior painting service that Atlanta has to offer. As a family owned business, we pledge to treat all of our customers like family.</p>
<br></br>



                            </div>
                            <Separator />

                            <br></br>                <br></br>
                    {/* Start Elements Area  */}
                    <div className="rwt-callto-action-area">
                        <div className="wrapper">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Let's Get Started!"
                                        title = ""
                                        description = ""
                                    />
                                </div>
                            </div>
                            <CalltoActionFive />
                        </div>
                        <br></br>                <br></br>
                    </div>
                    {/* End Elements Area  */}

                    <Separator />

                {/* Start Elements Area  */}
                <div className="rwt-testimonial-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb--10">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Customer Feedback"
                                    title = "What People Are Saying"
                                    description = "Interior painting is a hassle for any home or business owner, so why not leave it to the experts? At J. Stevens Painting, we have nearly 20 years of interior painting that Atlanta trusts. The next time you need interior painting services, give us a call and you will see why Atlanta chooses J. Stevens Painting for their paint contracting and service needs!"
                                />
                            </div>
                        </div>
                        <TestimonialOne column="col-lg-4 col-md-6 col-12 mt--30" teamStyle="card-style-default testimonial-style-one" />
                    </div>
                </div>
                {/* End Elements Area  */}

     
                <OnlineReviewsAwards />   
                <br></br>
                <br></br> 
                <CalltoActionBottom />
                            <RequestOne />
                            <br></br>
                <br></br> 
                <Copyright />
            </main>
        </>
    )
}
export default InteriorPainting;