import React from 'react';
import { FiPhone, FiMapPin, FiFile } from "react-icons/fi";


const AboutFive = () => {
    return (
        <div className="about-style-5 rn-section-gapBottom">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="row row--0 about-wrapper align-items-center theme-shape">
                            <div className="col-lg-6">
                                <div className="thumbnail">
                                    <img src="https://jstevenspainting.com/images/about/J-Steve-and-Wife.png" alt="About Images" />
                                </div>
                            </div>
                            <div className="col-lg-6 mt_md--30 mt_sm--30">
                                <div className="content">
                                    <div className="inner">
                                        <h4 className="title">Meet Jeff and Maria</h4>
                                        <p>We feel strongly at J. Stevens Painting that our experience and work speaks for itself. Whatever kind of painting you’re looking for, J. Stevens Painting offers a wide range of services to help you with all of your painting needs.</p>
                                        <ul className="contact-address">
                                            <li><FiFile /> Licensed & Insured Professional Painters</li>
                                            <li><FiPhone /> 770.365.6889</li>
                                            <li><FiMapPin /> Serving Metro Atlanta & North Georgia</li>
                                        </ul>
                                        <div className="download-button mt--20">
                                            <a className="btn-default round btn-border btn-large" href="/contact"><span>Get a FREE Estimate</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AboutFive;