import React from "react";
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';


import HeaderOne from '../common/header/HeaderOne';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import Slider from "react-slick";
import { BannerActivation } from "../utils/script";
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import ServiceOne from '../elements/service/ServiceOne';
import AboutTwo from '../elements/about/AboutTwo';
import TestimonialOne from "../elements/testimonial/TestimonialOne";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import OnlineReviewsAwards from "../elements/contact/OnlineReviewsAwards";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';
var BlogListData = BlogClassicData.slice(0, 3);

const BannerData = [
    {
        image: "/images/bg/Exterior-Marietta-2019.jpg",
        title: "Residential House Painter",
        description: "J. Stevens Painting is a full-service interior and exterior residential house painter serving Atlanta and the surrounding metro area."
    },
    {
        image: "/images/bg/Interior-Alpharetta-2019.jpg",
        title: "Interior Painter",
        description: "Interior house painting is a hassle for any home owner, so why not leave it to the experts? We have 20+ years of interior painting experience!"
    },
    {
        image: "/images/bg/Exterior-Kennesaw-2019.jpg",
        title: "Exterior Painting",
        description: "Does your home or business need a fresh coat of paint? Call J. Stevens Painting and we can take care of your exterior residential painting right away!"
    },
]


const PopupData = [
    {
        id: "01",
        image: "https://jstevenspainting.com/images/portfolio/portfolio-01.jpg",
        popupLink: [
            'https://jstevenspainting.com/images/portfolio/portfolio-01.jpg',
            'https://www.youtube.com/watch?v=xshEZzpS4CQ',
            'https://jstevenspainting.com/images/portfolio/portfolio-03.jpg',
            'https://jstevenspainting.com/images/portfolio/portfolio-04.jpg',
            'https://www.youtube.com/watch?v=xshEZzpS4CQ',
            'https://jstevenspainting.com/images/portfolio/portfolio-05.jpg',
        ],
    },
    {
        id: "02",
        image: "https://jstevenspainting.com/images/portfolio/portfolio-02.jpg",
        popupLink: [
            'https://www.youtube.com/watch?v=xshEZzpS4CQ',
        ],
    },
    {
        id: "03",
        image: "https://jstevenspainting.com/images/portfolio/portfolio-03.jpg",
        popupLink: [
            'https://jstevenspainting.com/images/portfolio/portfolio-03.jpg',
        ],
    },
    {
        id: "04",
        image: "https://jstevenspainting.com/images/portfolio/portfolio-04.jpg",
        popupLink: [
            'https://jstevenspainting.com/images/portfolio/portfolio-04.jpg',
        ],
    },
    {
        id: "05",
        image: "https://jstevenspainting.com/images/portfolio/portfolio-04.jpg",
        popupLink: [
            'https://www.youtube.com/watch?v=xshEZzpS4CQ',
        ],
    },
    {
        id: "06",
        image: "https://jstevenspainting.com/images/portfolio/portfolio-05.jpg",
        popupLink: [
            'https://jstevenspainting.com/images/portfolio/portfolio-05.jpg',
        ],
    },
]

const TravelAgency = () => {
    return (
        <>
            <Helmet>
        <title>Atlanta Painting Company | Atlanta Painting Contractor | J. Stevens Painting</title>
        <meta name="description" content="J. Stevens Painting serves as Atlanta’s house painter to the surrounding metropolitan Atlanta area and into North Georgia." />
        <meta name="keywords" content="painting, painter, residential painting, atlanta house painter" />
      </Helmet>
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small" HeaderSTyle="header-transparent" />
                
                {/* Start Slider Area  */}
                <Slider className="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow" {...BannerActivation}>
                    {BannerData.map((data, index) => (
                        <div key={index} className="single-slide">
                            <div className="height-950 bg_image" data-black-overlay="5" style={{backgroundImage: `url(${process.env.PUBLIC_URL} ${data.image})`}}>
                                <div className="container">
                                    <div className="row row--30 align-items-center">
                                        <div className="col-12">
                                            <div className="inner text-center">
                                                <h2 className="title" dangerouslySetInnerHTML={{__html: data.title}}></h2>
                                                <p className="description" dangerouslySetInnerHTML={{__html: data.description}}></p>
                                                <div className="button-group mt--30">
                                                    <Link className="btn-default" to="/contact">Get A Free Estimate</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
                {/* End Slider Area  */}

                {/* Start Service Area  */}
                <div className="rn-service-area">                
                <br></br>
                    <div className="container">
                        <ServiceOne 
                        serviceStyle = "service__style--1 icon-circle-style"
                        textAlign = "text-center"
                        />
                 <br></br>
                 <br></br>
                 <Separator />
                 <br></br>
                 <br></br>

                 <div>
                    <center>
                    <h1>Atlanta Painting Company</h1>
                    </center>
                    <p>J. Stevens Painting is an <b>Atlanta painting company</b> serving the surrounding metropolitan Atlanta area and into North Georgia. Whether you are looking to update your living space or increase your curb appeal, you'll love how new paint transforms your home. Our professional house painters can take a worn and dull looking interior or exterior and give your home a fresh and new look that you or potential buyers will love! With over 20 years of experience as a locally owned and operated <b><a href="https://jstevenspainting.com/residential-painting">Atlanta residential painter</a></b>, we provide piece of mind to our customers while producing exceptional resulting in our house painting.</p>
                 </div>
                 <br></br>
                 <br></br>
                    </div>
                </div>
                {/* End Service Area  */}
                <Separator />
                
                <AboutTwo/>

                <Separator />

                <div className="main-content">
                    <div className="rwt-portfolio-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "Professional Painting Services"
                                            title = "Gallery of Our Recent Work"
                                            description = ""
                                        />
                                </div>
                            </div>
                            <PortfolioOne Column="col-lg-4 col-md-6 mt--30 portfolio"  />
                        </div>
                    </div>
                </div>

                {/* Start Testimonial Area  */}
                <div className="rwt-testimonial-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Customer Feedback"
                                        title = "What People Are Saying"
                                        description = ""
                                    />
                            </div>
                        </div>
                        <TestimonialOne column="col-lg-4 col-md-6 col-sm-6 col-12 mt--30" teamStyle="card-style-default testimonial-style-one style-two" />
                    </div>
                </div>
                {/* End Testimonial Area  */}
                <OnlineReviewsAwards />   

                <Separator />
            <div>
            <br></br>
            </div>
                <div className="blog-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Servicing Metro Atlanta and North Georgia"
                                    title = "Service Areas"
                                    description = ""
                                />
                            </div>
                        </div>
                    </div>
                </div> 
                <div id="mydiv">
    <iframe id="myframe" 
        height="1200px" 
        scrolling="yes" 
        src="https://www.google.com/maps/d/embed?mid=1gUtTEUyTm-cqV20kour0NtFgYyZBrCxc&hl=en&ehbc=2E312F" 
        width="100%">
    </iframe>
</div>
                
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default TravelAgency;
