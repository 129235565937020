import React from 'react';
import {Link} from "react-router-dom";

const Nav = () => {
    return (
        <ul className="mainmenu">
            <li><Link to="/">Home</Link></li>

            <li className="has-droupdown"><Link to="/about-us">About</Link>
                <ul className="submenu">
                <li><Link to="/about-us">Licensed & Insured</Link></li>
                <li><Link to="/about-us">Locally Owned & Operated</Link></li>
                <li><Link to="/about-us">20+ Years of Experience</Link></li>
                </ul>
            </li>

            <li className="has-droupdown"><Link to="#">Painting Services</Link>
                <ul className="submenu">
                    <li><Link to="/residential-painting">Residential Painting</Link></li>
                    <li><Link to="/interior-painting">Interior Painting</Link></li>
                    <li><Link to="/exterior-painting">Exterior Painting</Link></li>
                    <li><Link to="/popcorn-removal">Popcorn Removal</Link></li>
                    <li><Link to="/wallpaper-removal">Wallpaper Removal</Link></li>
                    <li><Link to="/concrete-floor-coatings">Concrete Floor Coatings</Link></li>
                </ul>
            </li>

            <li className="with-megamenu"><Link to="#">Service Areas</Link>
                <div className="rn-megamenu">
                    <div className="wrapper">
                        <div className="row row--0">
                            <div className="col-lg-3 single-mega-item">
                                <ul className="mega-menu-item">
                                    <li><Link to="/acworth-painting-contractor">Acworth</Link></li>
                                    <li><Link to="/painting-contractors-in-adairsville">Adairsville</Link></li>
                                    <li><Link to="/painting-contractors-in-alpharetta">Alpharetta</Link></li>
                                    <li><Link to="/blue-ridge-painting-contractor">Blue Ridge</Link></li>
                                    <li><Link to="/ball-ground-painting-contractor">Ball Ground</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-3 single-mega-item">
                                <ul className="mega-menu-item">
                                    <li><Link to="/painter-in-canton-ga">Canton</Link></li>
                                    <li><Link to="/painting-contractors-in-cartersville">Cartersville</Link></li>
                                    <li><Link to="/painting-contractors-in-cumming/">Cumming</Link></li>
                                    <li><Link to="/dahlonega-painting-contractor">Dahlonega</Link></li>
                                    <li><Link to="/painting-contractors-in-ellijay">Ellijay</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-3 single-mega-item">
                                <ul className="mega-menu-item">
                                    <li><Link to="/painting-contractors-in-jasper">Jasper</Link></li>
                                    <li><Link to="/johns-creek-painting-contractor">Johns Creek</Link></li>
                                    <li><Link to="/painting-contractors-in-marietta">Marietta</Link></li>
                                    <li><Link to="/milton-painting-contractor">Milton</Link></li>
                                    <li><Link to="/kennesaw-painting-contractor">Kennesaw</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-3 single-mega-item">
                                <ul className="mega-menu-item">
                                    <li><Link to="/roswell-painting-contractor">Roswell</Link></li>
                                    <li><Link to="/sandy-springs-painting-contractor">Sandy Springs</Link></li>
                                    <li><Link to="/smyrna-painting-contractor">Smyrna</Link></li>
                                    <li><Link to="/vinings-painting-contractor">Vinings</Link></li>
                                    <li><Link to="/woodstock-painting-contractor">Woodstock</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </li>


            <li className="has-droupdown"><Link to="/contact">Contact</Link></li>
        </ul>
    )
}
export default Nav;
