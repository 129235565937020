import React from 'react';
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";

const OnlineReviewsAwards = () => {
    return (
        <>
                <div>
<center><h4>Read More Reviews Online</h4></center>                        <br></br>
                                    </div>

                <div className="rn-contact-address mt_dec--30">
                        <div className="onlinerow">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div>
                                    <div>
                                        <center>
                                    <img src="https://jstevenspainting.com/images/testimonial/bestoflife2022-jstevens-real.png" alt="Google" width="120" height="120" />
                                    </center>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="onlinerow">
                                <div>
                                    <div>
                                        <center>
                                        <a href="https://www.angi.com/companylist/us/ga/canton/j-stevens-painting-reviews-6298143.htm">
                                    <img src="https://jstevenspainting.com/images/testimonial/angie2021-jstevens-real.png" alt="Google" width="120" height="120" />
                                    </a>
                                    </center>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <br></br>
                <br></br> 
                <br></br>
                <br></br> 

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="onlinerow">
                                <div>
                                    <div>
                                        <center>
                                    <img src="https://jstevenspainting.com/images/testimonial/bestoflife2021-jstevens-real.png" alt="Google" width="120" height="120" />
                                    </center>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="rn-contact-address mt_dec--30">
                        <div className="onlinerow">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div>
                                    <div>
                                        <center>
                                        <a href="https://www.google.com/search?q=j+stevens+painting&source=hp&ei=ByWRYoS8HJ-HytMP6tOQQA&iflsig=AJiK0e8AAAAAYpEzF-WUSGVPI-LLZthR-SnseWEkVpTd&gs_ssp=eJzj4tZP1zcsyUhPtjTNMmC0UjWosLBIMzUztkwzMktJNDK0MLUyqDA3T0k2MTFPtbA0SbVIM0_1EspSKC5JLUvNK1YoSMzMK8nMSwcA3PUVmQ&oq=jstevens+painting&gs_lcp=Cgdnd3Mtd2l6EAMYADIKCC4QxwEQrwEQDTIECAAQDTIFCAAQhgM6CwguEIAEELEDEIMBOhEILhCABBCxAxCDARDHARCjAjoICAAQsQMQgwE6BQguEIAEOggILhCxAxCDAToLCAAQgAQQsQMQgwE6CwguEIAEEMcBEKMCOggIABCABBCxAzoOCC4QgAQQsQMQgwEQ1AI6BAgAEAM6BQgAEIAEOhEILhCABBCxAxDHARDRAxDUAjoICC4QsQMQ1AI6BwgAEIAEEAo6DQguEIAEEMcBEKMCEAo6CgguEIAEENQCEAo6BwguEIAEEAo6DQguEIAEEMcBEK8BEAo6BggAEB4QCjoECAAQHjoECAAQCjoECC4QCjoGCAAQHhAWOggIABAeEA8QFjoICAAQHhAIEA1QAFjNFGDbG2gAcAB4AIABbIgB6wqSAQQxNi4xmAEAoAEB&sclient=gws-wiz#lrd=0x88f5639f26da2185:0x77dc447e894e8f7e,1,,,">
                                    <img src="https://jstevenspainting.com/images/testimonial/google.png" alt="Google" width="120" height="120" />
                                    </a>
                                    </center>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="onlinerow">
                                <div>
                                    <div>
                                        <center>
                                        <a href="https://www.facebook.com/JStevensPainting/reviews/">
                                    <img src="https://jstevenspainting.com/images/testimonial/facebook.png" alt="Google" width="120" height="120" />
                                    </a>
                                    </center>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br></br>
                <br></br> 
                <br></br> 

        </>
    )
}
export default OnlineReviewsAwards;