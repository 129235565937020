import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import RequestOne from "./RequestOne";

const Contact = () => {
    return (
        <>
            <SEO title="Contact for a Free Estimate" />
            <Layout>
                <div><br></br><br></br>
<center><h1 class="title theme-gradient h2">Contact J. Stevens Painting <br />For a FREE Estimate</h1></center>                        <br></br>
                                        <center><a class="btn-default round btn-border btn-large" href="tel:770.365.6889">Call 770.365.6889</a></center>
                                    </div>

                <div className="main-content">
                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area">
                        <div className="container">

                            <RequestOne />
                        </div>
                    </div>
                    {/* End Contact Area  */}
                </div>
            </Layout>
        </>
    )
}
export default Contact;