import React from 'react';
import {Link} from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";
import { Helmet } from 'react-helmet';
import HeaderOne from '../common/header/HeaderOne';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import AboutFive from '../elements/about/AboutFive';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import TestimonialOne from "../elements/testimonial/TestimonialOne";
import Separator from "../elements/separator/Separator";
import ProgressbarTwo from "../elements/progressbar/ProgressbarTwo";
import BrandFour from "../elements/brand/BrandFour";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";
import ContactOne from "../elements/contact/ContactOne";
import OnlineReviewsAwards from "../elements/contact/OnlineReviewsAwards";
import CalltoActionBottom from "../elements/calltoaction/CalltoActionBottom";
import RequestOne from "../elements/contact/RequestOne";



var BlogListData = BlogClassicData.slice(0, 3);


const WallpaperRemoval = () => {
    return (
        <>
            <Helmet>
        <title>Atlanta Wallpaper Removal Company | J. Stevens Painting</title>
        <meta name="description" content="At J. Stevens Painting, we know that people's value of a home changes by removing dated design trends like wallpaper. The good news is that J. Stevens Painting offers wallpaper removal for homeowners all around metro Atlanta." />
        <meta name="keywords" content="painting, painter, residential painting" />
      </Helmet>            <main className="page-wrapper">
                
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-perosonal-portfolio height-750 bg_image" data-black-overlay="1" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/banner/wallpaper-removal.jpg)`}}>
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="col-12">
                                <div className="inner text-center">
                                    <h1 className="title">Wallpaper Removal Company</h1>
                                    <div className="button-group mt--30">
                                        <Link className="btn-default round btn-border btn-large" to="tel:770.365.6889">Call 770.365.6889</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <AboutFive />

                <div className="container">

                <center><h2>Metro Atlanta & North Georgia <br/>Wallpaper Removal Services</h2></center>
                <br></br>
                <p>Wallpaper has been used for home decorating since the 18th century. Back then, it was considered a luxury item. Wallpaper with floral patterns was commonly used to signify elegance. As mass production took hold in the 19th century, more homeowners decorated their houses with wallpaper. Velvet wallpaper became popular and many homeowners matched the upholstery patterns of their furniture with wallpaper patterns to create a cohesive look.</p>
                <p>By the 20th century, wallpaper was commonplace in working class homes. Many wallpaper manufacturers created patterns to match the architectural styles of modern American homes. Some of these patterns were so distinctive that samples of wallpaper are on display in museums today.</p>
                <p>Wallpaper remains a popular decorating technique. Many people use wallpaper to cover up imperfections or damage on walls or give their home interior a unique look. Many older homes in the greater Atlanta area have wallpaper adorning their interior.</p>
                <p>However, if you want to bring your home into the 21st century, you may need some assistance with wallpaper removal. Wallpaper removal may seem like a quick “do it yourself” project but, in fact, it is a time consuming task that can wear out even the toughest homeowner. This may discourage you from updating the look of your home.</p>
                            </div>
                            <Separator />

                            <br></br>                <br></br>
                    {/* Start Elements Area  */}
                    <div className="rwt-callto-action-area">
                        <div className="wrapper">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Let's Get Started!"
                                        title = ""
                                        description = ""
                                    />
                                </div>
                            </div>
                            <CalltoActionFive />
                        </div>
                        <br></br>                <br></br>
                    </div>
                    {/* End Elements Area  */}

                    <Separator />

                {/* Start Elements Area  */}
                <div className="rwt-testimonial-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb--10">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Customer Feedback"
                                    title = "What People Are Saying"
                                    description = "At J. Stevens Painting, we know that people's value of a home changes by removing dated design trends like wallpaper. The good news is that J. Stevens Painting offers wallpaper removal for homeowners all around metro Atlanta. Call J. Stevens Painting and we can take care of your wallpaper removal right away!"
                                />
                            </div>
                        </div>
                        <TestimonialOne column="col-lg-4 col-md-6 col-12 mt--30" teamStyle="card-style-default testimonial-style-one" />
                    </div>
                </div>
                {/* End Elements Area  */}

     
                <OnlineReviewsAwards />   
                <br></br>
                <br></br> 
                <CalltoActionBottom />
                            <RequestOne />
                            <br></br>
                <br></br> 
                <Copyright />
            </main>
        </>
    )
}
export default WallpaperRemoval;