import React from 'react';
import {Link} from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";
import { Helmet } from 'react-helmet';
import HeaderOne from '../common/header/HeaderOne';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import AboutFive from '../elements/about/AboutFive';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import TestimonialOne from "../elements/testimonial/TestimonialOne";
import Separator from "../elements/separator/Separator";
import ProgressbarTwo from "../elements/progressbar/ProgressbarTwo";
import BrandFour from "../elements/brand/BrandFour";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";
import ContactOne from "../elements/contact/ContactOne";
import OnlineReviewsAwards from "../elements/contact/OnlineReviewsAwards";
import CalltoActionBottom from "../elements/calltoaction/CalltoActionBottom";
import RequestOne from "../elements/contact/RequestOne";



var BlogListData = BlogClassicData.slice(0, 3);


const PopcornRemoval = () => {
    return (
        <>
            <Helmet>
        <title>Atlanta Popcorn Removal Company | J. Stevens Painting</title>
        <meta name="description" content="Our Popcorn Removal contractors make sure that your home or business gets the best Popcorn Removal application possible. " />
        <meta name="keywords" content="painting, painter, residential painting" />
      </Helmet>            <main className="page-wrapper">
                
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-perosonal-portfolio height-750 bg_image" data-black-overlay="1" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/banner/popcorn-ceiling.jpg)`}}>
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="col-12">
                                <div className="inner text-center">
                                    <h1 className="title">Popcorn Removal Company</h1>
                                    <div className="button-group mt--30">
                                        <Link className="btn-default round btn-border btn-large" to="tel:770.365.6889">Call 770.365.6889</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <AboutFive />

                <div className="container">

                <center><h2>Metro Atlanta & North Georgia <br/>Popcorn Removal Services</h2></center>
                <br></br>
                <p>Popcorn ceilings became popular in the late 1950s. Also known as cottage cheese ceiling or Stucco ceilings, this home decorating technique peaked in the 1980s. Many homeowners liked the bright, white look and interesting texture of popcorn ceilings. Others liked the way popcorn ceilings reduced noise.</p>
                <p>Despite falling out of favor, there are still many homes in the Atlanta area with popcorn ceilings today. Many people encounter popcorn ceilings when they buy existing homes since these houses tend to be older. Some homes have popcorn ceilings in kitchens, hallways and bedrooms. Some homes even have popcorn ceilings in the garage.</p>
                <p>Our Popcorn Removal contractors make sure that your home or business gets the best Popcorn Removal application possible. We thoroughly pressure wash the area before painting and apply mildecide. We also scrape down any inconsistencies, like peeling paint, from your surfaces and prime all bare wood. Finally, we will caulk any open gaps to ensure that no moisture can interfere with the look and comfort of your home or business.</p>
                <p>Unfortunately, some homebuilders used popcorn ceilings to cover poor workmanship and imperfections in drywall. The rough texture that gives popcorn ceilings their distinctive look easily hides defects. Many popcorn ceilings were made with asbestos, a toxic ingredient that was banned in 1978. After 1979, contractors used styrofoam or paper-based products to create the popcorn texture.</p><br></br>

                            </div>
                            <Separator />

                            <br></br>                <br></br>
                    {/* Start Elements Area  */}
                    <div className="rwt-callto-action-area">
                        <div className="wrapper">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Let's Get Started!"
                                        title = ""
                                        description = ""
                                    />
                                </div>
                            </div>
                            <CalltoActionFive />
                        </div>
                        <br></br>                <br></br>
                    </div>
                    {/* End Elements Area  */}

                    <Separator />

                {/* Start Elements Area  */}
                <div className="rwt-testimonial-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb--10">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Customer Feedback"
                                    title = "What People Are Saying"
                                    description = "At J. Stevens Painting, we know that people's value of a home changes by removing dated design trends like popcorn ceilings. Having your popcorn ceiling removed is an excellent way to freshen up the look of your home’s interior. Call J. Stevens Painting and we can take care of your popcorn removal needs right away!"
                                />
                            </div>
                        </div>
                        <TestimonialOne column="col-lg-4 col-md-6 col-12 mt--30" teamStyle="card-style-default testimonial-style-one" />
                    </div>
                </div>
                {/* End Elements Area  */}

     
                <OnlineReviewsAwards />   
                <br></br>
                <br></br> 
                <CalltoActionBottom />
                            <RequestOne />
                            <br></br>
                <br></br> 
                <Copyright />
            </main>
        </>
    )
}
export default PopcornRemoval;