import React from 'react';
import {Link} from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";
import { Helmet } from 'react-helmet';
import HeaderOne from '../common/header/HeaderOne';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import AboutFive from '../elements/about/AboutFive';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import TestimonialOne from "../elements/testimonial/TestimonialOne";
import Separator from "../elements/separator/Separator";
import ProgressbarTwo from "../elements/progressbar/ProgressbarTwo";
import BrandFour from "../elements/brand/BrandFour";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";
import ContactOne from "../elements/contact/ContactOne";
import OnlineReviewsAwards from "../elements/contact/OnlineReviewsAwards";
import CalltoActionBottom from "../elements/calltoaction/CalltoActionBottom";
import RequestOne from "../elements/contact/RequestOne";



var BlogListData = BlogClassicData.slice(0, 3);


const ConcreteFlooring = () => {
    return (
        <>
            <Helmet>
        <title>Atlanta Concrete Flooring Company | J. Stevens Painting</title>
        <meta name="description" content="Concrete floor coatings offer a durable and attractive finish that can be easily customized. If you’re looking for something a little more outside the box, concrete floor coatings have you covered there too. Call J. Stevens Painting today!" />
        <meta name="keywords" content="painting, painter, residential painting" />
      </Helmet>            <main className="page-wrapper">
                
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-perosonal-portfolio height-750 bg_image" data-black-overlay="1" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/banner/concrete-flooring.jpg)`}}>
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="col-12">
                                <div className="inner text-center">
                                    <h1 className="title">Concrete Flooring Company</h1>
                                    <div className="button-group mt--30">
                                        <Link className="btn-default round btn-border btn-large" to="tel:770.365.6889">Call 770.365.6889</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <AboutFive />

                <div className="container">

                <center><h2>Metro Atlanta & North Georgia <br/>Concrete Flooring Services</h2></center>
                <p>Concrete coatings have been a flooring staple in commercial decor for decades, but up until recently it hasn’t been a common option for residential flooring. Luckily, things are changing and the best kept commercial flooring secret has made its way into homes across the country.</p> 
                <p>Whether you’re tired of the boring gray concrete floors in your basement and garage, or you’re looking for a durable alternative to carpet, hardwood and vinyl tiles, concrete floor coatings offer a durable and attractive finish that can be easily customized.</p>
<br></br>

                            </div>
                            <Separator />

                            <br></br>                <br></br>
                    {/* Start Elements Area  */}
                    <div className="rwt-callto-action-area">
                        <div className="wrapper">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Let's Get Started!"
                                        title = ""
                                        description = ""
                                    />
                                </div>
                            </div>
                            <CalltoActionFive />
                        </div>
                        <br></br>                <br></br>
                    </div>
                    {/* End Elements Area  */}

                    <Separator />

                    <div className="container">
                    <br></br>

                    <center><h3>Types of Popular Concrete Floor Coatings</h3></center>
                <p>The term “coating,” as it relates to concrete flooring, refers to any liquid or semi-liquid material that can be applied to cured concrete. While this term can also be said to include cleaners and primers, it generally refers to one or more of the following products:</p>

                <br></br>
                <center><h4>Acid or Water Based Stains</h4></center>
                <p>Acid-based stains are a mixture of water, hydrochloric acid, and acid-soluble metallic salts. They work by reacting chemically with the hydrated lime (calcium hydroxide) below the surface of the concrete. The acid in the stain lightly etches the surface, clearing the way for the metallic salts to penetrate the concrete. The metallic salts then react with elements in the concrete, producing color. Once the stain has reacted with the concrete, it becomes a permanent part of it.</p>
                <p>Water based stains are typically a blend of acrylic polymers and pigments. The end result is much more predictable because this type of stain doesn’t rely on a chemical reaction to produce color. Water based stains also produce a more opaque and vibrant finish than acid-based stains.</p>
                <p>Because they penetrate so far into the concrete, acid and water based stains produce a long-lasting finish that resists mildew and damage from UV rays and heavy traffic. Acid-based stains produce translucent muted tones that mimic natural stone, wood grain or metal oxidation. Water based stains produce a brighter and more consistent tone and are available in wider range of colors.</p>

                <br></br>
                <center><h4>Concrete Dyes</h4></center>
                <p>Concrete dye comes in both water based and solvent form. Much like acid and water based stains, concrete dye penetrates the concrete surface rather than sitting on top. Water based dyes have a marbled or variegated finish, while solvent-based dyes produce a much more uniform finish.</p>
                <p>Concrete dye penetrates more deeply than concrete stain, due to its smaller particles, but it is not UV-stable and will fade quickly when regularly exposed to sunlight.</p>
                
                <br></br>
                <center><h4>Tinted Sealers</h4></center>
                <p>Tinted concrete sealers produce a glossy finish with color suspended within. As with dyes, concrete sealer comes in water and solvent based forms. Both forms produce a semi-translucent finish, but water based sealer will produce a nearly transparent finish that is good for very subtle effects. Tinted concrete sealer can either be purchased premixed or custom created using tint concentrates.</p>
                
<br></br>
</div>
                            <Separator />

                            <br></br>                <br></br>
                    {/* Start Elements Area  */}
                    <div className="rwt-callto-action-area">
                        <div className="wrapper">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Let's Get Started!"
                                        title = ""
                                        description = ""
                                    />
                                </div>
                            </div>
                            <CalltoActionFive />
                        </div>
                        <br></br>                <br></br>
                    </div>
                    {/* End Elements Area  */}

                    <Separator />

                {/* Start Elements Area  */}
                <div className="rwt-testimonial-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb--10">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Customer Feedback"
                                    title = "What People Are Saying"
                                    description = "Concrete floor coatings are extremely versatile materials that can recreate the effect of multiple finishes and designs. They can even be used to mimic more expensive flooring types like hardwood, marble, granite and ceramic mosaic tile. If you’re looking for something a little more outside the box, concrete floor coatings have you covered there too. Call J. Stevens Painting today!"
                                />
                            </div>
                        </div>
                        <TestimonialOne column="col-lg-4 col-md-6 col-12 mt--30" teamStyle="card-style-default testimonial-style-one" />
                    </div>
                </div>
                {/* End Elements Area  */}

     
                <OnlineReviewsAwards />   
                <br></br>
                <br></br> 
                <CalltoActionBottom />
                            <RequestOne />
                            <br></br>
                <br></br> 
                <Copyright />
            </main>
        </>
    )
}
export default ConcreteFlooring;