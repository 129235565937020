import React from 'react'
import { Helmet } from 'react-helmet';
import HeaderOne from '../common/header/HeaderOne';
import FooterTwo from '../common/footer/FooterTwo';
import BrandThree from '../elements/brand/BrandThree';
import AboutTwo from '../elements/about/AboutTwo';
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import Separator from "../elements/separator/Separator";

const AboutUs = () => {
    return (
        <>
            <Helmet>
        <title>About Us | J. Stevens Painting</title>
        <meta name="description" content="20+ Years of Residential Painting Experience, Fully Licensed and Insured, Locally Owned and Operated." />
        <meta name="keywords" content="painting, painter, residential painting" />
      </Helmet>            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-850 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-12.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">About J. Stevens Painting</span></h3>
                                    </div>
                                    <h1 className="title display-one">About Our<br />Painting Company</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}


                {/* Start Service Area  */}
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">Since 1998, we have painted thousands of homes throughout metro Atlanta and beyond</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">We take pride in the many happy customers and lasting relationships we have been able to achieve. We know what it takes to have and to keep well-pleased customers. If we are given the opportunity, we will do all we can to add you to our long list of satisfied customers in and around Atlanta, Georgia.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}



                <Separator />

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Benefits of J. Stevens Painting"
                                    title = "Services provide for you."
                                    description = "Providing first-class service with top-of-the-line paints, stains & caulks. "
                                    />
                            </div>
                        </div>
                        <ServiceOne 
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--25"
                            textAlign = "text-center"
                            />
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />

                <AboutTwo image="https://jstevenspainting.com/images/about/contact-image.jpg" />
                  
                <Separator />







                <FooterTwo />
            </main>
        </>
    )
}

export default AboutUs;
