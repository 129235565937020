import React from 'react';
import {Link} from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";
import { Helmet } from 'react-helmet';
import HeaderOne from '../common/header/HeaderOne';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import AboutFive from '../elements/about/AboutFive';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import TestimonialOne from "../elements/testimonial/TestimonialOne";
import Separator from "../elements/separator/Separator";
import ProgressbarTwo from "../elements/progressbar/ProgressbarTwo";
import BrandFour from "../elements/brand/BrandFour";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';
import CalltoActionFive from "../elements/calltoaction/CalltoActionFive";
import ContactOne from "../elements/contact/ContactOne";
import OnlineReviewsAwards from "../elements/contact/OnlineReviewsAwards";
import CalltoActionResidentialBottom from "../elements/calltoaction/CalltoActionResidentialBottom";
import RequestOne from "../elements/contact/RequestOne";



var BlogListData = BlogClassicData.slice(0, 3);


const ResidentialPainting = () => {
    return (
        <>
            <Helmet>
        <title>Atlanta Residential Painter Company | J. Stevens Painting</title>
        <meta name="description" content="We are THE residential painter Atlanta calls on for detailed estimates, competitive pricing and on-time appointments." />
        <meta name="keywords" content="painting, painter, residential painting, residential painter" />
      </Helmet>            <main className="page-wrapper">
                
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-perosonal-portfolio height-750 bg_image" data-black-overlay="1" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/banner/residential-painting.jpg)`}}>
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="col-12">
                                <div className="inner text-center">
                                    <h1 className="title">Atlanta Residential Painter</h1>
                                    <div className="button-group mt--30">
                                        <Link className="btn-default round btn-border btn-large" to="tel:770.365.6889">Call 770.365.6889</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <AboutFive />

                <div className="container">

                <center><h2>Metro Atlanta & North Georgia <br/>Residential Painting Services</h2></center>
                <br></br>
                <p>If you are looking for an <b>Residential Painter</b> in Atlanta, you have come to the right place! J. Stevens Painting is a full-service interior and exterior residential painter serving Atlanta and the surrounding metro area. We are THE residential painting company Atlanta calls on for detailed estimates, competitive pricing and on-time appointments. Whether you’d like to paint an accent wall, or the entire interior and exterior of your house, no job is too small, or too big for J. Stevens Painting. We always go the extra mile for our customers to ensure there is minimal disruption to your household or business. J. Stevens Painting will complete your painting project beautifully and on time.</p>
<br></br>



                            </div>
                            <Separator />

                            <br></br>                <br></br>
                    {/* Start Elements Area  */}
                    <div className="rwt-callto-action-area">
                        <div className="wrapper">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Let's Get Started!"
                                        title = ""
                                        description = ""
                                    />
                                </div>
                            </div>
                            <CalltoActionFive />
                        </div>
                        <br></br>                <br></br>
                    </div>
                    {/* End Elements Area  */}

                    <Separator />

                {/* Start Elements Area  */}
                <div className="rwt-testimonial-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb--10">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Customer Feedback"
                                    title = "What People Are Saying"
                                    description = "We feel strongly at J. Stevens Painting that our experience and work speaks for itself. Whatever kind of painting you’re looking for, J. Stevens Painting offers a wide range of services to help you with all of your painting needs. Please visit our extensive photo gallery displaying our work and you will see why Atlanta chooses J. Stevens Painting for their paint contracting and service needs!"
                                />
                            </div>
                        </div>
                        <TestimonialOne column="col-lg-4 col-md-6 col-12 mt--30" teamStyle="card-style-default testimonial-style-one" />
                    </div>
                </div>
                {/* End Elements Area  */}

     
                <OnlineReviewsAwards />   
                <br></br>
                <br></br> 
                <CalltoActionResidentialBottom />
                            <RequestOne />
                            <br></br>
                <br></br> 
                <Copyright />
            </main>
        </>
    )
}
export default ResidentialPainting;